<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div class="h-handle-button" slot="buttonGroup">
				<div class="h-b">
					<el-button type="primary" size="small" @click="add"
						v-right-code="'Customer_Right_Stock_StockCompare_AddStockCompare'">新增</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange" highlight-current-row>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName==='CompareNo'"><el-button type="text" @click="rowClick(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
		<el-dialog title="新增库存对账" :visible.sync="addStockReconciliationVisible">
			<el-form :model="stockReconciliationdata" ref="_addForm">
				<el-form-item>
					<el-col :span="3" class="form-title">对账仓库：</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-select v-model="stockReconciliationdata.WarehouseNos" multiple collapse-tags
								placeholder="全部仓库" :maxlength="150">
								<el-option v-for="item in warehouseList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">库存类型：</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-select v-model="stockReconciliationdata.InventoryTypes" multiple collapse-tags
								placeholder="全部库存类型" :maxlength="150">
								<el-option v-for="item in inventoryTypeList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">货主：</el-col>
					<el-col :span="5">
						<el-select v-model="stockReconciliationdata.CustomerCode" collapse-tags placeholder="请选择"
							:maxlength="200">
							<el-option v-for="item in customerList" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">对账商品：</el-col>
					<el-col :span="17">
						<el-radio-group v-model="stockReconciliationdata.IsAllProduct" @change="productChange">
							<el-radio :label="true">全部商品</el-radio>
							<el-radio :label="false">指定商品</el-radio>
						</el-radio-group>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title" v-if="!stockReconciliationdata.IsAllProduct">指定商品：</el-col>
					<el-col :span="17" v-if="!stockReconciliationdata.IsAllProduct">
						<el-form-item>
							<el-tag :key="tag" v-for="tag in stockReconciliationdata.ProductNos" closable
								:disable-transitions="false" @close="handleClose(tag)">
								{{tag}}
							</el-tag>
							<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
								size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
							</el-input>
							<el-button v-else class="button-new-tag" size="small" @click="showInput">选择商品</el-button>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addStockReconciliationVisible = false">取 消</el-button>
				<el-button type="primary" @click="addStockReconciliation">确 定</el-button>
			</div>
		</el-dialog>
		<DialogEx :options="productOptions" title="选择商品" @onOk="onProductOk">
			<Product ref="product" :config="productConfig" @onSelectedRow="onProductSelectedRow"></Product>
		</DialogEx>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.Utils.lazy(() => {
				this.Event.$on("reloadPageList", () => this.reloadPageList());
			});
			var _this = this;
			this.initialize();
			this.getWarehouseList();
			this.getCustomerList();
			this.getInventoryTypeList();
		},
		data() {
			return {
				multipleSelection: [],
				warehouseList: [],
				inventoryTypeList: [],
				currentProductSelectedRows: [],
				addStockReconciliationVisible: false,
				queryParam: {
					PageIndex: 1,
					PageSize: 10
				},
				queryParamCompare: {
					pageIndex: 1,
					pageSize: 10,
					Params: {}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0,
				},
				productOptions: {
					visible: false,
					size: 'small'
				},
				productConfig: {
					isDetailDisplay: true,
					isSelectionDisplay: true,
					currentType: 2,
					currentCCode: '',
					ProductNos: [],
					CustomerCode: ""
				},
				inputVisible: false,
				inputValue: '',
				stockReconciliationdata: {
					WarehouseNos: [],
					InventoryTypes: [],
					IsAllProduct: true,
					ProductNos: [],
					CustomerCode: ""
				},
				customerList: []
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stockcompare/searchstockcomparelist", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;
				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			rowClick(row) {
				var _this = this;
				var result = {};
				this.$ajax.send("omsapi/stockcompare/getstockcomparebyid", "get", {
					id: row.CompareID
				}, (data) => {
					result = data.Result;
				});
				this.queryParamCompare.Params = {};
				this.queryParamCompare.Params.ReconciliationNo = {
					fieldName: 'ReconciliationNo',
					fieldValue: row.CompareNo
				};
				this.$ajax.send("omsapi/stockcompareitem/getlist", "post", this.queryParamCompare, (data) => {
					result.StockCompareItemList = data;
				});
				//this.$ajax.query("omsapi/stock/StockCompareItem/GetNumberByWarehouseNo", "post", this.queryParamCompare, data => {
				//    result.SearchCountData = data.Result;
				//});
				this.Event.$emit("clearStockCompareForm");
				this.Event.$emit("showlist");
				this.onChangeEditDataSource(result);
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
			getWarehouseList() {
				var _this = this;
				this.$ajax.query("omsapi/customerinfo/getallwarehouse", "get", {}, data => {
					if (data.IsSuccess) {
						_this.warehouseList = data.Result;
					}
				});
			},
			getCustomerList() {
				var _this = this;
				this.$ajax.query("omsapi/customerinfo/getcurrentcustomers", "get", {}, data => {
					if (data.IsSuccess) {
						_this.customerList = data.Result;
					}
				});
			},
			getInventoryTypeList() {
				var _this = this;
				this.$ajax.query("omsapi/customerinfo/getinventorytypes", "get", {}, data => {
					if (data.IsSuccess) {
						_this.inventoryTypeList = data.Result;
					}
				});
			},
			onProductOk: function() {
				var _this = this;
				var rows = this.currentProductSelectedRows;
				if (_this.stockReconciliationdata.ProductNos != null) {
					for (var i = 0; i < rows.length; i++) {
						var item = rows[i];
						for (var j = 0; j < _this.stockReconciliationdata.ProductNos.length; j++) {
							if (item.ProductNo == _this.stockReconciliationdata.ProductNos[j]) {
								_this.stockReconciliationdata.ProductNos.splice(j, 1);
								break;
							}
						}
						_this.stockReconciliationdata.ProductNos.push(item.ProductNo);
					}
				} else {
					_this.stockReconciliationdata.ProductNos = [];
					for (var i = 0; i < rows.length; i++) {
						var item = rows[i];
						_this.stockReconciliationdata.ProductNos.push(item.ProductNo);
					}
				}
			},
			onProductSelectedRow: function(data) {
				this.currentProductSelectedRows = data;
			},
			showInput() {
				this.productConfig.CustomerCode=this.stockReconciliationdata.CustomerCode;
				this.productOptions.visible = true
			},
			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.stockReconciliationdata.ProductNos.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},
			handleClose(tag) {
				this.stockReconciliationdata.ProductNos.splice(this.stockReconciliationdata.ProductNos.indexOf(tag), 1);
			},
			productChange(value) {
				if (value) {
					this.stockReconciliationdata.ProductNos = [];
				} else {

					if (this.stockReconciliationdata.CustomerCode == null || this.stockReconciliationdata.CustomerCode ==
						"") {
						this.Utils.messageBox("货主必填.", "error");
						this.stockReconciliationdata.IsAllProduct = true;

						return;
					}
					this.stockReconciliationdata.IsAllProduct = false;
				}
			},
			add() {
				this.stockReconciliationdata.WarehouseNos = [];
				this.stockReconciliationdata.InventoryTypes = [];
				this.stockReconciliationdata.ProductNos = [];
				this.stockReconciliationdata.IsAllProduct = true;
				this.addStockReconciliationVisible = true;

			},
			addStockReconciliation() {
				var data = this.stockReconciliationdata;
				if (data.WarehouseNos.length == 0) {
					var warehouseNos = this.Utils.selectionsToArr(this.warehouseList, "WarehouseNo");
					data.WarehouseNos = warehouseNos;
				}
				if (data.InventoryTypes.length == 0) {
					var inventoryTypes = this.Utils.selectionsToArr(this.inventoryTypeList, "Key");
					data.InventoryTypes = inventoryTypes;
				}
				if (!data.IsAllProduct && data.ProductNos.length == 0) {
					this.Utils.messageBox("商品不能为空.", "error");
					return;
				}
				if (data.CustomerCode == null || data.CustomerCode == "") {
					this.Utils.messageBox("货主必填.", "error");
					return;
				}
				var _this = this;
				this.$ajax.send("omsapi/stockcompare/create", "post", data, data => {
					this.$parent.syncDataSource();
					_this.addStockReconciliationVisible = false;
					_this.reloadPageList();
					this.Utils.messageBox("保存成功.", "success");
				});

			}
		},
		components: {
			"Product": resolve => {
				require(['../../../selector/productselector.vue'], resolve)
			},
		}
	}
</script>
<style>
</style>